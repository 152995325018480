import { colors, sizes, effects, typography } from '@dixa/lib-shared-tokens';
import { mixins } from '../../generated/generated_mixin_names.js';

import './styles/index.scss';

export class Styleguide {
  constructor() {}

  renderRowHeader(id, heading) {
    return `
      <thead>
        <tr id=${id}>
          <th scope="col">${heading}</th>
          <th scope="col">Values</th>
          <th scope="col">Examples</th>
        </tr>
      </thead>
    `;
  }

  renderRow(prop) {
    return `
      <tr>
        <th scope="row"><code>${prop.name}</code></th>
        <td><code>${prop.value}</code></td>
        ${prop.example}
      </tr>
    `;
  }

  renderSpacing(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-size-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <div class="box" style="width: ${prop.value}; height: ${prop.value};"></div>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderBorder(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-size-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <div class="box" style="border: ${prop.value} solid #617598;"></div>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderBorderRadius(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-size-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <div class="box" style="border-radius: ${prop.value};"></div>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderColor(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-color-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <div class="box" style="background-color: ${prop.value}; border: 1px solid #f1f3f8;"></div>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderFontFamily(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-typography-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <p style="font-family: ${prop.value}; font-size: 14px;">Lorem Ipsum</p>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderFontWeight(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-typography-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <p style="font-weight: ${prop.value}; font-size: 14px;">Lorem Ipsum</p>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderFontSize(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-typography-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <p style="font-size: ${prop.value};">Lorem Ipsum</p>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderFontColor(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-typography-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <p style="color: ${prop.value}; font-size: 14px;">Lorem Ipsum</p>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderLineHeight(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-typography-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <p style="line-height: ${prop.value}; font-size: 14px; background-color: #d7dfea;">Lorem Ipsum</p>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderLetterSpacing(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-typography-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <p style="letter-spacing: ${prop.value}; font-size: 14px;">Lorem Ipsum</p>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderOpacity(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-effect-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <p style="color: black; font-size: 14px; opacity: ${newProp.value};">Lorem Ipsum</p>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderBoxShadow(props, type) {
    return props.map((prop) => {
      let newProp = {};
      newProp.name = `$dixa-effect-${type}-${prop.key}`;
      newProp.value = prop.value;
      newProp.example = `
        <td>
          <div style="color: black; height: 100px; width: 100%; border-radius: 4px; font-size: 14px; box-shadow: ${newProp.value};"></div>
        </td>
      `;

      return this.renderRow(newProp);
    });
  }

  renderSection(type, heading, fn) {
    const tokens =
      sizes[type] || effects[type] || colors[type] || typography[type];
    const tokensMap = Object.entries(tokens).map(([key, value]) => ({
      key,
      value,
    }));

    if (!tokens) {
      return '';
    }

    return `
      <section>
        <table>
          <colgroup>
            <col class="w">
            <col>
            <col>
          </colgroup>
          ${this.renderRowHeader(type, heading)}
          <tbody>
            ${fn.call(this, tokensMap, type).join('')}
          </tbody>
        </table>
      </section>
    `;
  }

  renderMixin(mixins) {
    return mixins.map((mixin) => {
      return `
        <li><code>${mixin}</code></li>
      `;
    });
  }

  renderMixins(mixins) {
    return `
      <section>
        <ul>
          ${this.renderMixin(mixins).join('')}
        </ul>
      </section>
    `;
  }

  render() {
    return `
    <header role="banner" class="banner">
      <div class="container">
        <h1>Dixa Design Tokens</h1>
        <div class="logo"></div>
      </div>
    </header>
    <div class="container">
      <main role="main">
      <h2>How to import</h2>
      <ul>
        <li><code>@import '@dixa/lib-shared-tokens/dist/scss';</code></li>
        <li><code>@import (reference) '@dixa/lib-shared-tokens/dist/less';</code></li>
        <li><code>import { colors, sizes, effects, typography } from '@dixa/lib-shared-tokens';</code></li>
        <li><code>import { COLOR_GREEN_600 } from '@dixa/lib-shared-tokens';</code></li>
      </ul>
      <h2>Tokens</h2>
      ${this.renderSection('spacer', 'Spacer', this.renderSpacing)}
      ${this.renderSection('border', 'Border', this.renderBorder)}
      ${this.renderSection(
        'border-radius',
        'Border Radius',
        this.renderBorderRadius,
      )}
      ${this.renderSection('purple', 'Purple', this.renderColor)}
      ${this.renderSection('green', 'Green', this.renderColor)}
      ${this.renderSection('blue', 'Blue', this.renderColor)}
      ${this.renderSection('orange', 'Orange', this.renderColor)}
      ${this.renderSection('red', 'Red', this.renderColor)}
      ${this.renderSection('neutral', 'Neutral', this.renderColor)}
      ${this.renderSection('family', 'Font Family', this.renderFontFamily)}
      ${this.renderSection('weight', 'Font Weight', this.renderFontWeight)}
      ${this.renderSection('size', 'Font size', this.renderFontSize)}
      ${this.renderSection('line-height', 'Line Height', this.renderLineHeight)}
      ${this.renderSection('color', 'Font Color', this.renderFontColor)}
      ${this.renderSection(
        'letter-spacing',
        'Letter Spacing',
        this.renderLetterSpacing,
      )}
      ${this.renderSection('opacity', 'Opacity', this.renderOpacity)}
      ${this.renderSection('box-shadow', 'Box Shadow', this.renderBoxShadow)}
      <h2>Mixins</h2>
      ${this.renderMixins(mixins)}
      </main>
    </div>
    `;
  }
}
